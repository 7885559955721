/* eslint-disable react/no-multi-comp */
import React, {useEffect, useState} from 'react'
import {useLocation, matchPath} from 'react-router'
import {Link as RouterLink} from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {makeStyles} from '@material-ui/styles'
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Link,
  colors
} from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'
import NavItem from 'src/components/NavItem'
import navConfig from './navConfig'
import {AccessGuard} from "src/admin-utils"
import configureStore from "../../redux/configureStore"
import __ from "../../admin-utils/LanguageHelper"
import themeConfig from "../../theme/themeConfig"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    backgroundColor: themeConfig.menu.backgroundColor,
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
  },
  navigation: {
    padding: theme.spacing(0, 2, 10, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  logo: {
    width: themeConfig.menu.logo.width,
    height: themeConfig.menu.logo.height || 'auto',
    padding: theme.spacing(2)
  },
  logoContainer: {
    background: '#fff',
    borderRadius: '50%',
    margin: '20px auto',
    textAlign: 'center',
    width: 90,
    height: 90,
    border: '3px solid #F7AD00'
  },
  headerContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${themeConfig.menu.header.borderColor}`,
    ...themeConfig.menu.header
  }

}))

function renderNavItems({items, subheader, key, ...rest}) {
  return (
    <List key={key}>
      {subheader && <ListSubheader style={{color: themeConfig.menu.items.subHeaderColor}} disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({acc, item, ...rest}),
        []
      )}
    </List>
  )
}

const hasAccess = (item, permissions) => {
  // Check permissions
  let permission = `${item.href.replace('/', '').replace(/\//g, '_')}_browse`

  // Logout link
  if(item.alwaysShow) {
    return true
  }

  // return permissions.includes(permission)
  return true
}

const childHasAccess = (items, permissions) => {
  let access = false
  items.map(item => {
    access = hasAccess(item, permissions)
  })

  // return access
  return true
}

function reduceChildRoutes({acc, pathname, item, depth = 0, permissions}) {

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    })

    if (childHasAccess(item.items, permissions)) {
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={item.href}
          label={item.label}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            permissions
          })}
        </NavItem>
      )
    }

  } else {
    if (hasAccess(item, permissions)) {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      )
    }
  }

  return acc
}

function NavBar({
                  openMobile,
                  onMobileClose,
                  className,
                  ...rest
                }) {
  const classes = useStyles()
  const location = useLocation()
  const [status, setStatus] = useState('online')
  const session = useSelector((state) => state.session)

  const handleStatusToggle = () => {
    const statusSeq = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online'
    }

    setStatus((prevStatus) => statusSeq[prevStatus])
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }

    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <nav className={classes.navigation}>
        {navConfig.map((list) => renderNavItems({
          items: list.items,
          subheader: list.subheader,
          pathname: location.pathname,
          key: list.subheader,
          permissions: session.permissions
        }))}
      </nav>
    </div>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(classes.mobileDrawer, classes.drawer)
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div className={classes.logoContainer}>
            <img src={'/images/logo.png'} className={classes.logo}/>
          </div>

          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(classes.desktopDrawer, classes.drawer)
          }}
          open
          variant="persistent"
        >

          <div className={classes.headerContainer}>
            <img src={'/images/logo.png'} className={classes.logo}/>
          </div>

          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default NavBar
