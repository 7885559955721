import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import FormRender from "./FormRender"
import {FormattedMessage} from "react-intl"
import {Button, Card, CardActions, Divider, Slide, Typography} from "@material-ui/core"
import {create, getDetails, update as APIUpdate, update} from "../../API"
import clsx from "clsx"
import LoadingOverlay from "react-loading-overlay"
import {useSelector} from "react-redux"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import './Forms.scss'
import validate from "validate.js"
import __ from "../../LanguageHelper"
import _ from 'lodash'
import {Form as FinalForm} from 'react-final-form'
import moment from "moment"
import {GridLoader} from 'react-spinners'
import {theme} from "../../../theme"
import swal from "sweetalert"

// Form Options
import {formOptions} from '../../../utils/globals'

const {keepData, clearInputs} = formOptions

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  column: {
    paddingBottom: '10px!important'
  },
  cancelButton: {
    marginLeft: 'auto'
  },
  confirmExitButton: {
    paddingLeft: 30,
    paddingRight: 40,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  confirmButton: {
    paddingLeft: 30,
    paddingRight: 40,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  header: {
    marginTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  subHeader: {
    paddingLeft: 25,
  },
  cardContent: {
    height: 'calc(100vh - 125px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  modalCardContent: {
    maxHeight: 'calc(100vh - 138px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  filler: {
    height: 200
  }
}))

// Date validation
validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return +moment.utc(value)
  },
  // Input is a unix timestamp
  format: function (value, options) {
    let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"
    return moment.utc(value).format(format)
  }
})

const Form = ({fields, repeatableFields, repeatableTitle, modal, inline, title, editTitle, id, endpoint, params, onFinish, handleModalClose, width, hidden, icon, defaultValues, cancelTitle, saveExitTitle, saveTitle, tableRef, buttons, editable = true, withButtons = false, successMessage}) => {
  const classes = useStyles()
  const {token, user: {branch_id}} = useSelector((state) => state.session)

  const [loading, setLoading] = useState(false)
  const [fetch, setFetch] = useState(false)
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const [values, setValues] = useState(defaultValues)
  const [repeatableData, setRepeatableData] = useState([])

  if (!buttons) {
    buttons = id ? ['saveExit', 'cancel'] : ['save', 'saveExit', 'cancel']
  }

  if (inline && !withButtons) {
    buttons = ['save']
  }

  // Get details
  async function getData() {
    if (_.isObject(endpoint)) {
      endpoint = endpoint.get
    }

    let response = await getDetails(endpoint, id, token, params)
    setValues(formatValues(response.data))
  }

  const formatValues = values => {
    const fields = flattedFields()
    let formattedValues = {}
    Object.keys(values).map(key => {
      let value = values[key],
        field = fields.find(f => f.field === key)

      if (field && field.type === "time") {
        if (moment(value, 'hh:mm A').isValid()) {
          value = value.replace('صباحا', 'AM')
          value = value.replace('مساءا', 'PM')

          value = moment(value, 'hh:mm A').format()
        } else {
          value = null
        }
      }

      if (field && field.type === "date") {
        value = moment(value).toDate()
      }

      if (field && field.type === "switch") {
        value = value === 1
      }

      if (field && field.type === "dropdown" && field.multiple) {
        value = value.map(item => item[field.val])
      }

      if (field && field.type === "password") {
        value = null
      }

      if (value) {
        formattedValues[key] = value
      }
    })

    return formattedValues
  }

  useEffect(() => {
    if (id) {
      setFetch(true)
      getData().then(() => {
        setFetch(false)
      })
    } else {
      // Set default values
      let initialValues = {}
      flattedFields().map(field => {
        if (field.multiple) {
          initialValues[field.field] = []
        }
      })

      if (branch_id > 0) {
        initialValues.branch_id = branch_id
      }

      if (values) {
        initialValues = {...values, ...initialValues}
      }

      setValues(initialValues)
    }
  }, [id])

  const flattedFields = (unFlattedFields = fields) => {
    let flattenFields = []
    unFlattedFields.map((field) => {
      if (Array.isArray(field)) {
        field.map((subField) => {
          flattenFields.push(subField)
        })
      } else {
        flattenFields.push(field)
      }
    })

    return flattenFields
  }

  const validateValues = (data) => {
    let schema = {}

    if (data.latitude && data.longitude) {
      data.location = {
        latitude: data.latitude,
        longitude: data.longitude
      }
    }

    flattedFields().map((field) => {
      let rules = {},
        fieldRules = field.rules || []

      let numberRuleMin,
        numberRuleMax

      if (_.isFunction(field.rules)) {
        fieldRules = field.rules(!!id)
      }

      if (fieldRules.length > 0) {
        fieldRules.map((rule) => {
          switch (rule) {
            case 'required':
              rules.presence = {
                allowEmpty: false,
                message: __('الحقل مطلوب', 'Field is required')
              }
              break
            case 'email':
              rules.email = {
                message: __('صيغة البريد الالكتروني غير صحيحة', 'Email is not valid')
              }
              break
            case 'date':
              rules.datetime = {
                message: __('تأكد من ادخال تاريخ صحيح', 'Date is not valid')
              }
              break
          }

          // Max words
          if (rule.includes('maxWords:')) {
            let slicedRule = rule.split(':')
            rules.length = {
              maximum: parseInt(slicedRule[1]),
              tooLong: __('الحد الاقصى لعدد الكلمات هو %{count} كلمة', 'Maximum words allowed is %{count} words'),
              tokenizer: function (value) {
                return value.split(/\s+/g)
              }
            }
          }

          // Max number
          if (rule.includes('maxNumber:')) {
            let slicedRule = rule.split(':')
            let numText = __('رقم', 'number')
            if (slicedRule[2]) {
              numText = slicedRule[2]
            }

            numberRuleMax = {
              onlyInteger: true,
              notLessThanOrEqualTo: __(`اقصى ${numText} مقبول هو %{count}`, `Maximum ${numText} allowed is %{count}`),
              lessThanOrEqualTo: parseInt(slicedRule[1]),
              tokenizer: function (value) {
                return value
              }
            }
          }

          // Min number
          if (rule.includes('minNumber:')) {
            let slicedRule = rule.split(':')
            let numText = __('رقم', 'number')
            if (slicedRule[2]) {
              numText = slicedRule[2]
            }

            numberRuleMin = {
              onlyInteger: true,
              notGreaterThanOrEqualTo: __(`اقل ${numText} مقبول هو %{count}`, `Minimum ${numText} allowed is %{count}`),
              greaterThanOrEqualTo: parseInt(slicedRule[1]),
              tokenizer: function (value) {
                return value
              }
            }
          }
        })

        if (numberRuleMin || numberRuleMax) {
          rules.numericality = {
            ...numberRuleMin,
            ...numberRuleMax
          }
        }

        schema[field.field] = rules
      }

      if (!data[field]) {
        data[field] = null
      }
    })

    return validate(data, schema, {
      prettify: function prettify(string) {
        return ' '
      },
    })
  }

  function isFormField(field) {
    let isField = false
    // Search in normal fields
    let searchInFields = flattedFields().find(f => f.field === field)
    if (searchInFields) isField = true

    // Search in hidden fields
    if (hidden && _.isObject(hidden)) {
      let searchInHidden = Object.keys(hidden).find(f => f === field)
      if (searchInHidden) isField = true
    }

    // Location fields
    if (['latitude', 'longitude', 'location'].includes(field)) isField = true

    return isField
  }

  const cleanifyValues = values => {
    let cleanValues = {}
    Object.keys(values).map(key => {
      if (isFormField(key)) {
        let value = values[key]
        let field = flattedFields().find(f => f.field === key)

        if (field && value && (field.type === "date" || field.type === "month") && value.isValid && value.isValid()) {
          cleanValues[key] = value.format("YYYY-MM-DD HH:mm:ss")
        } else if (field && field.type === "date") {
          cleanValues[key] = moment(value, 'DD-MM-YYYY').format("YYYY-MM-DD HH:mm:ss")
        } else if (field && field.type === "time") {
          cleanValues[key] = moment(value, 'hh:mm A').format("hh:mm A")
        } else if (field && field.type === "switch") {
          cleanValues[key] = value ? 1 : 0
        } else if (key === "location") {
          cleanValues["latitude"] = values.location.lat
          cleanValues["longitude"] = values.location.lng
        } else if (key === "latitude") {
          if (cleanValues.location) {
            cleanValues.location.latitude = value
          } else {
            cleanValues.location = {latitude: value}
          }
        } else if (key === "longitude") {
          if (cleanValues.location) {
            cleanValues.location.longitude = value
          } else {
            cleanValues.location = {longitude: value}
          }
        } else {
          cleanValues[key] = value
        }
      }
    })

    return cleanValues
  }

  const submitForm = async (values, form = null) => {
    // console.log(values)
    // return

    let data = cleanifyValues(values)

    // Validation
    let errors = validateValues(data)
    if (errors) {
      setErrors(errors)
      return
    }

    // Hidden fields
    if (hidden) {
      Object.keys(hidden).map((key) => {
        if (hidden[key].toString().includes(':')) {
          data[key] = data[hidden[key].replace(':', '')]
        } else {
          data[key] = hidden[key]
        }
      })
    }

    console.log(hidden)

    // Form submission
    setErrors([])
    setLoading(true)

    if (_.isObject(endpoint)) {
      endpoint = id ? endpoint.edit : endpoint.add
    }

    let result

    // Clean data
    let cleanData = {}
    Object.keys(data).map(key => {
      if (!_.isUndefined(data[key]) && !_.isNull(data[key]) && data[key].toString().length > 0) {
        cleanData[key] = data[key]
      }
    })

    if (id) {
      result = await update(endpoint, id, cleanData, token)
    } else {
      result = await create(endpoint, cleanData, token)
    }

    setLoading(false)

    if (!result) return

    // On save callback
    onFinish && onFinish(result.data, !!id)

    if(successMessage) {
      // swal success
      swal({
        title: successMessage,
        icon: "success",
        button: __('حسنا', 'Ok'),
      }).finally()
    }

    if (inline) return

    // Add data to table instantly
    if (tableRef) {
      // let insertedRecord = result.data
      // let modifiedData = tableRef.current.dataManager.data
      //
      // if (id) {
      //   let rowIndex = modifiedData.findIndex(x => x.id === id)
      //   modifiedData[rowIndex] = insertedRecord
      // } else {
      //   modifiedData.push(insertedRecord)
      // }


      // tableRef.current.dataManager.setData(modifiedData)
      // tableRef.current.onEditingCanceled("update", insertedRecord)
    }

    // Hide form
    if (form) {
      !keepData && form.reset()
    } else {
      handleModalClose()
    }

    setOpen(true)

    if (keepData) {
      if (clearInputs) {
        let newValues = {...values}

        flattedFields().map(field => {
          if (['text', 'string', 'password'].includes(field.type)) {
            delete newValues[field.field]
          }
        })

        setValues(newValues)
      }
    } else {
      setValues({})
    }
  }

  function processFields() {
    return fields.map((field, i) => {
      if (_.isArray(field)) {
        return field.map((subField, index) => {
          if (!subField.showIn) {
            return subField
          } else if (subField.showIn.includes(id ? 'edit' : 'add')) {
            return subField
          }
        })
      } else {
        if (!field.showIn) {
          return field
        } else if (field.showIn.includes(id ? 'edit' : 'add')) {
          return field
        }
      }
    })
  }

  const handleClose = (event) => {
    setOpen(false)
  }

  const onSubmit = async values => {
    console.log(values)
  }

  // Mutators
  /** Clears a form value */
  const clear = ([name], state, {changeValue}) => {
    changeValue(state, name, () => undefined)
  }

  /** Change value */
  const setValue = ([field, value], state, {changeValue}) => {
    changeValue(state, field, () => value)
  }

  return (
    <>
      <Card
        className={modal ? clsx(classes.root) : null}
        style={{width}}
      >
        <FinalForm
          mutators={{clear, setValue}}
          onSubmit={onSubmit}
          initialValues={values}
          validate={validate}
          render={({form, handleSubmit, reset, submitting, pristine, values}) => {
            return (
              <form noValidate>
                <div className={classes.header}>
                  <Typography align="left" variant="h4" style={{marginBottom: 15}}>{id ? editTitle : title}</Typography>
                  <Divider/>
                </div>

                <LoadingOverlay
                  active={loading || fetch}
                  spinner={<GridLoader color={theme.palette.primary.main} size={10}/>}
                >
                  <>
                    {((!fetch && id) || !id) ? <FormRender
                      className={modal ? classes.modalCardContent : inline ? classes.inlineCardContent : classes.cardContent}
                      fields={processFields()}
                      errors={errors}
                      values={values}
                      form={form}
                    /> : <div className={classes.filler}/>}

                    {(repeatableFields && ((!fetch && id) || !id)) && (
                      <>

                        {repeatableTitle && <Typography align="left" variant="h5" className={classes.subHeader}>
                          {repeatableTitle}
                        </Typography>}

                        <FormRender
                          repeatable
                          className={modal ? classes.modalCardContent : inline ? classes.inlineCardContent : classes.cardContent}
                          fields={repeatableFields}
                          flattenedFields={flattedFields(repeatableFields)}
                          errors={errors}
                          values={values}
                          form={form}
                          onRepeatableAdd={() => {
                            setRepeatableData([...repeatableData, values])
                          }}
                          onRepeatableDelete={(index) => {
                            const clonedData = [...repeatableData]
                            delete clonedData[index]
                            setRepeatableData(clonedData)
                          }}
                          repeatableValues={repeatableData}
                        />
                      </>
                    )}
                  </>
                </LoadingOverlay>

                {editable && <LoadingOverlay active={loading}>
                  <Divider/>
                  <CardActions>
                    {buttons.includes('save') && <Button
                      disabled={fetch || loading}
                      className={classes.confirmButton}
                      variant="contained"
                      onClick={() => submitForm(values, form)}
                    >
                      {saveTitle || __('حفظ', 'Save')}
                    </Button>}
                    {buttons.includes('saveExit') && <Button
                      disabled={fetch || loading}
                      className={classes.confirmExitButton}
                      variant="contained"
                      onClick={() => submitForm(values)}
                    >
                      {saveExitTitle || __('حفظ و إنهاء', 'Save & Exit')}
                    </Button>}
                    {buttons.includes('cancel') && <Button
                      className={classes.cancelButton}
                      variant="contained"
                      onClick={() => handleModalClose()}
                    >
                      {cancelTitle || __('إلغاء', 'Cancel')}
                    </Button>}
                  </CardActions>
                </LoadingOverlay>}
              </form>
            )
          }}
        />
      </Card>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
        <Alert onClose={handleClose} color="success">
          {__('تم الحفظ بنجاح', 'Record saved successfully')}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        key={`loader`}
        open={fetch}
        TransitionComponent={Slide}
        message={__('جاري جلب البيانات...', 'Fetching data...')}
      />

      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        key={`loader`}
        open={loading}
        TransitionComponent={Slide}
        message={__('جاري حفظ البيانات...', 'Saving data...')}
      />
    </>
  )
}

export default Form
