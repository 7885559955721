import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks"
import IconButton from "@material-ui/core/IconButton"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import {Link} from "react-router-dom"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import VisibilityIcon from "@material-ui/icons/Visibility"
import Typography from "@material-ui/core/Typography"
import {FormattedMessage} from "react-intl"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import React, {useState} from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import LoadingOverlay from "react-loading-overlay"
import {remove_record} from "../../../API"
import {useSelector} from "react-redux"
import AccessGuard from "../../AccessGuard"
import {Grid, Hidden, LinearProgress, Slide, Tooltip} from "@material-ui/core"
import __ from "../../../LanguageHelper"
import Snackbar from "@material-ui/core/Snackbar"
import _ from "lodash"
import {AiOutlineEye, BiDetail, BiEdit, BiPrinter, BiTrash, FiEdit, VscOpenPreview} from "react-icons/all"
import {makeStyles} from "@material-ui/styles"

export const DeleteConfirm = ({open, handleClose, tableRef, endpoint, id, onDelete}) => {
  const {token} = useSelector((state) => state.session)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const isMulti = _.isArray(id)

  const deleteRecord = async () => {
    setLoading(true)

    let ids = isMulti ? id : [id]
    let progressValue = 100 / (ids.length - 1),
      currentProgress = progress

    for (const id of ids) {
      await remove_record(endpoint, id, token)

      currentProgress += progressValue
      setProgress(currentProgress)
    }

    tableRef.current.onQueryChange()

    onDelete && onDelete()

    setLoading(false)
    setProgress(0)
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isMulti && <LinearProgress variant="determinate" value={progress}/>}
        <LoadingOverlay
          active={loading}
        >
          <DialogTitle id="alert-dialog-title">{__('تأكيد الحذف', 'Confirm delete')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {__(`هل أنت متأكد من حذف ${isMulti ? 'البيانات' : 'البيان'}؟`, `Are you sure you want to delete ${isMulti ? 'selected records' : 'this record'}?`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              {__('إلغاء', 'Cancel')}
            </Button>
            <Button onClick={() => {
              deleteRecord().finally()
            }} color="primary" autoFocus>
              {__('تأكيد الحذف', 'Confirm Delete')}
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>

      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        key={`loader`}
        open={loading}
        TransitionComponent={Slide}
        message={__('جاري حذف البيانات...', 'Deleting record...')}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.text.primary
  },
  menuIconSmall: {
    color: theme.palette.text.primary,
    fontSize: 18
  },
  noPadding: {
    padding: 0
  }
}))

const ActionMenu = ({id, buttons = [], editAction, viewAction, printAction, normalView, rowData, protect = [], deleteRecord, endpoint, compact}) => {
  const classes = useStyles()
  const popupState = usePopupState({variant: 'popover', popupId: 'actionMenu'})

  if (protect.includes(rowData.id)) return null

  const settings = buttons.find((button) => button.isSettings)

  return (
    <div>
      <Hidden mdDown>
        <div style={{whiteSpace: 'nowrap', textAlign: 'center'}}>
          {buttons.map((button) => {
            if (!_.isObject(button) || !button.icon) return null
            if (button.visibility && button.visibility(rowData) === false) return null

            const ButtonIcon = button.icon

            return (
              <Tooltip title={button.title}>
                <IconButton
                  className={compact && classes.noPadding}
                  onClick={() => button.action(rowData, id)}
                  size="medium"
                >
                  <ButtonIcon className={classes.menuIcon} style={button.style}/>
                </IconButton>
              </Tooltip>
            )
          })}

          {(buttons.includes('print')) && (
            <Tooltip title={__("طباعة", "Print")}>
              <IconButton
                className={compact && classes.noPadding}
                onClick={() => {
                  printAction && printAction(rowData)
                  popupState.close()
                }}
                size="medium"
              >
                <BiPrinter/>
              </IconButton>
            </Tooltip>
          )}

          <AccessGuard permission={'read'} endpoint={endpoint}>
            {buttons.includes('show') && (
              <>
                {normalView ? (
                  <Tooltip title={__("عرض", "Show")}>
                    <IconButton component={Link} to={`${window.location.pathname}/${id}`}>
                      <BiDetail className={classes.menuIcon}/>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={__("عرض", "Show")}>
                    <IconButton
                      className={compact && classes.noPadding}
                      onClick={() => {
                        popupState.close()
                        viewAction && viewAction(rowData)
                      }}
                      size="medium">
                      <BiDetail className={classes.menuIcon}/>
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </AccessGuard>

          {(!settings || settings?.editVisibility(rowData)) && (
            <AccessGuard permission={'edit'} endpoint={endpoint}>
              {(buttons.includes('edit')) && (
                <Tooltip title={__("تعديل", "Edit")}>
                  <IconButton
                    className={compact && classes.noPadding}
                    onClick={() => {
                      editAction && editAction(rowData)
                      popupState.close()
                    }}
                    size="medium">
                    <BiEdit className={classes.menuIcon}/>
                  </IconButton>
                </Tooltip>
              )}
            </AccessGuard>
          )}

          {(!settings || settings?.deleteVisibility(rowData)) && (
            <AccessGuard permission={'delete'} endpoint={endpoint}>
              {(buttons.includes('delete')) && (
                <Tooltip title={__("حذف", "Delete")}>
                  <IconButton
                    className={compact && classes.noPadding}
                    onClick={() => {
                      deleteRecord()
                      popupState.close()
                    }}
                    size="medium">
                    <BiTrash style={{color: '#bf1111'}}/>
                  </IconButton>
                </Tooltip>
              )}
            </AccessGuard>
          )}
        </div>
      </Hidden>

      <Hidden lgUp>
        <IconButton {...bindTrigger(popupState)} size="medium">
          <MoreVertIcon/>
        </IconButton>

        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          {(buttons.includes('print')) && (
            <MenuItem onClick={() => {
              popupState.close()
              editAction && editAction(rowData)
            }}>
              <ListItemIcon>
                <BiPrinter className={classes.menuIconSmall}/>
              </ListItemIcon>
              <Typography variant="inherit">{__('طباعة', 'Print')}</Typography>
            </MenuItem>
          )}

          {buttons.map((button) => {
            if (!_.isObject(button)) return null
            if (button.visibility && button.visibility(rowData) === false) return null

            const ButtonIcon = button.icon
            return (
              <MenuItem onClick={() => button.action(id)}>
                <ListItemIcon>
                  <ButtonIcon className={classes.menuIconSmall}/>
                </ListItemIcon>
                <Typography variant="inherit">{button.title}</Typography>
              </MenuItem>
            )
          })}

          <AccessGuard permission={'read'} endpoint={endpoint}>
            {buttons.includes('show') && (
              <>
                {normalView ? (
                  <MenuItem component={Link} to={`${window.location.pathname}/${id}`}>
                    <ListItemIcon>
                      <BiDetail className={classes.menuIconSmall}/>
                    </ListItemIcon>
                    <Typography variant="inherit"><FormattedMessage id="datatable.show" defaultMessage="Show"/></Typography>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => {
                    popupState.close()
                    viewAction && viewAction(rowData)
                  }}>
                    <ListItemIcon>
                      <BiDetail className={classes.menuIconSmall}/>
                    </ListItemIcon>
                    <Typography variant="inherit"><FormattedMessage id="datatable.show" defaultMessage="Show"/></Typography>
                  </MenuItem>
                )}
              </>
            )}
          </AccessGuard>

          {(!settings || settings?.editVisibility(rowData)) && (
            <AccessGuard permission={'edit'} endpoint={endpoint}>
              {(buttons.includes('edit')) && (
                <MenuItem onClick={() => {
                  popupState.close()
                  editAction && editAction(rowData)
                }}>
                  <ListItemIcon>
                    <BiEdit className={classes.menuIconSmall}/>
                  </ListItemIcon>
                  <Typography variant="inherit"><FormattedMessage id="datatable.edit" defaultMessage="Edit"/></Typography>
                </MenuItem>
              )}
            </AccessGuard>
          )}

          {(!settings || settings?.deleteVisibility(rowData)) && (
            <AccessGuard permission={'delete'} endpoint={endpoint}>
              {(buttons.includes('delete')) && (
                <MenuItem onClick={() => {
                  deleteRecord()
                  popupState.close()
                }}>
                  <ListItemIcon>
                    <BiTrash style={{color: '#bf1111'}} className={classes.menuIconSmall}/>
                  </ListItemIcon>
                  <Typography style={{color: '#bf1111'}} variant="inherit"><FormattedMessage id="datatable.delete" defaultMessage="Delete"/></Typography>
                </MenuItem>
              )}
            </AccessGuard>
          )}
        </Menu>
      </Hidden>
    </div>
  )
}

export default ActionMenu
