import {colors} from '@material-ui/core'
import themeConfig from "./themeConfig"
import Color from 'color'

const white = '#FFFFFF'

const {primaryColor, secondaryColor, iconColor} = themeConfig.general

export default {
  primary: {
    contrastText: white,
    dark: Color(primaryColor).darken(0.5).hex(),
    main: primaryColor,
    light: Color(primaryColor).lighten(0.8).hex()
  },
  secondary: {
    contrastText: white,
    dark: Color(secondaryColor).darken(0.5).hex(),
    main: secondaryColor,
    light: Color(secondaryColor).lighten(0.8).hex()
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
    white: white,
  },
  link: colors.blue[800],
  icon: iconColor,
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
}
