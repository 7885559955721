/* eslint-disable no-undef */
import React, {useState} from 'react'
import {Helmet} from 'react-helmet'
import {useParams} from 'react-router'
import PropTypes from 'prop-types'
import {makeStyles} from "@material-ui/styles"
import {Button, Container} from "@material-ui/core"
import {FormattedMessage} from "react-intl"
import BreadCrumb from "../BreadCrumb"
import DetailsPanel from "./DetailsPanel"
import {managementTitle, Notifications} from "../../../utils/globals"
import LoadingOverlay from "react-loading-overlay"
import {GridLoader} from "react-spinners"
import {theme} from "../../../theme"

export const isDev = process.env.NODE_ENV !== "production"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: isDev && 40
  },
  devBar: {
    backgroundColor: '#e4e7ea',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    borderBottom: '1px solid #d7dde2',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1
  },
  forceReload: {
    float: 'right'
  }
}))

function Page({title, subTitle, children, noBread, left, isLoading, hideManagement, ...rest}) {
  const classes = useStyles()
  let {id} = useParams()

  const [updater, setUpdater] = useState(true)

  return (
    <div {...rest} className={!noBread && classes.root}>

      {isDev && <div className={classes.devBar}>
        <Button
          className={classes.forceReload}
          variant="outlined"
          color="primary"
          size={'small'}
          onClick={() => {
            setUpdater(false)
            setTimeout(() => {
              setUpdater(true)
            })
          }}
        >
          {'Force Reload'}
        </Button>
      </div>}

      {title && (
        <>
          {title.props ? <FormattedMessage id={title.props.id}>
            {title => <Helmet><title>{title}</title></Helmet>}
          </FormattedMessage> : <Helmet><title>{title}</title></Helmet>}
        </>
      )}

      <LoadingOverlay
        active={isLoading}
        spinner={<GridLoader color={theme.palette.primary.main} size={10}/>}
      >
        <Container maxWidth={false}>
          {!noBread && <BreadCrumb title={title} managementTitle={managementTitle} hideManagement={hideManagement} subTitle={subTitle} left={left} back={id}/>}

          <Notifications/>

          {updater && children}
        </Container>
      </LoadingOverlay>
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.any
}

// Pusher
Page.pushView = function (params) {
  window.history.pushState({}, '', params.route)
  DetailsPanel.show(params)
}

Page.hideView = function (params) {
  window.history.pushState({}, '', params.route)
  DetailsPanel.hide()
}

export default Page
