/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'
import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard"/>
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      /* START_ROUTES */{
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/views/Dashboard'))
      },{
        path: '/dashboard/:id',
        exact: true,
        component: lazy(() => import('src/views/Dashboard/DashboardDetails'))
      },{
        path: '/clients',
        exact: true,
        component: lazy(() => import('src/views/Clients'))
      },{
        path: '/clients/:id',
        exact: true,
        component: lazy(() => import('src/views/Clients/ClientsDetails'))
      },{
        path: '/accounts',
        exact: true,
        component: lazy(() => import('src/views/Accounts'))
      },{
        path: '/accounts/:id',
        exact: true,
        component: lazy(() => import('src/views/Accounts/AccountsDetails'))
      },{
        path: '/user/management/roles',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles'))
      },{
        path: '/user/management/roles/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles/UserManagementRolesDetails'))
      },{
        path: '/user/management/users',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers'))
      },{
        path: '/user/management/users/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers/UserManagementUsersDetails'))
      },
	/* END_ROUTES */














      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  }
]
