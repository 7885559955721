import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import PropTypes from 'prop-types'
import {getProfile} from "../views/Login/API"
import AuthActions from "../redux/reducers/AuthRedux"
import LoadingOverlay from "react-loading-overlay"
import {GridLoader} from "react-spinners"
import {theme} from "../theme"

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function AuthGuard({permission, children}) {
  const session = useSelector((state) => state.session)
  const history = useHistory()
  const dispatch = useDispatch()
  const {token} = useSelector((state) => state.session)

  const [loading, setLoading] = useState(true)

  const getUserProfile = async () => {
    let userData = await getProfile(token)

    if (!userData) {
      dispatch(AuthActions.authLogin(false, {}, [], ''))
      history.push('/auth/login')
      return
    }

    setLoading(false)

    // Extract permissions
    let permissions = []
    userData.role.map(role => {
      role.permissions.map(permission => permissions.push(permission.name))
    })

    dispatch(AuthActions.authLogin(true, userData.user, permissions, token))
  }

  useEffect(() => {
    if (!session.loggedIn || !session.user) {
      history.push('/auth/login')
      setLoading(false)
      return
    }

    getUserProfile().finally()

    if (!session.permissions.includes(permission)) {
      // history.push('/errors/error-401')
    }
  }, [])

  if (loading) {
    return <LoadingOverlay
      active={loading}
      spinner={<GridLoader color={theme.palette.primary.main} size={10}/>}
    >
      <div style={{height: '100vh'}}/>
    </LoadingOverlay>
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string.isRequired
}

export default AuthGuard
