import {Box, Collapse} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import {AlertTitle} from "@material-ui/lab"
import __ from "../admin-utils/LanguageHelper"
import React, {useState} from "react"
import NotificationActions from "../redux/reducers/NotificationRedux"
import {useDispatch, useSelector} from "react-redux"
import {postRequest} from "../admin-utils/API"

export const NotificationItem = ({notification}) => {
  const {token} = useSelector((state) => state.session)
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()

  function readNotification() {
    setOpen(false)
    dispatch(NotificationActions.setNotification(notification.href, notification.id, 'remove'))
    postRequest(`notification/${notification.id}`, {read_at: Date.now()}, token)
  }

  return (
    <Collapse in={open}>
      <Box mb={1}>
        <Alert
          variant="filled"
          severity="warning"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                readNotification()
              }}
            >
              <CloseIcon fontSize="inherit"/>
            </IconButton>
          }
        >
          <AlertTitle>{__('تنبيه', 'Notification')}</AlertTitle>
          {notification.message}
        </Alert>
      </Box>
    </Collapse>
  )
}
