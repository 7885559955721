import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/styles'
import {Button, Grid, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {useHistory} from 'react-router'
import __ from "../../LanguageHelper"

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 30
  },
  backButton: {
    marginBottom: 15
  }
}))

function BreadCrumb({className, managementTitle, title, subTitle, left, back, hideManagement, ...rest}) {
  const classes = useStyles()
  let history = useHistory()

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>

          {back && <Button
            variant="outlined"
            color="secondary"
            className={classes.backButton}
            startIcon={__(<ArrowForwardIcon/>, <ArrowBackIcon/>)}
            onClick={() => history.goBack()}
          >
            {__('رجوع', 'Back')}
          </Button>}

          {!hideManagement && <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            {managementTitle || __('إدارة', 'MANAGEMENT')}
          </Typography>}

          {title && <Typography
            variant="h4"
          >
            {title}
          </Typography>}

          {subTitle && <Typography
            variant="subtitle2"
          >
            {subTitle}
          </Typography>}
        </Grid>
        {left && <Grid item>
          {left()}
        </Grid>}
      </Grid>
    </div>
  )
}

BreadCrumb.propTypes = {
  className: PropTypes.string,
  left: PropTypes.func,
  title: PropTypes.string,
  back: PropTypes.number
}

export default BreadCrumb
