import React, {useState} from 'react'
import {makeStyles} from "@material-ui/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing.unit,
  }
}))

const DetailsTabs = ({tabs = [], isVertical, className}) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  function TabPanel(props) {
    const {children, value, index, ...other} = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    )
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={isVertical ? 3 : 12} xs={isVertical ? 3 : 12}>
          <Paper square className={clsx(classes.container, className)}>
            <Tabs
              orientation={isVertical ? "vertical" : "horizontal"}
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map(tab => (
                <Tab label={tab.title}/>
              ))}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item md={isVertical ? 9 : 12} xs={isVertical ? 9 : 12}>
          {tabs.map(({content}, index) => (
            <TabPanel value={activeTab} index={index}>
              {content && content()}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default DetailsTabs
