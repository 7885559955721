import __ from "../admin-utils/LanguageHelper";
import logo from "./logo"
import React from "react"
import {useSelector} from "react-redux"
import {NotificationItem} from "../components/notificationItem"
import Box from "@material-ui/core/Box"

const baseDomain = 'https://integr8me-api.appsbackend.xyz'
const baseUrl = baseDomain + '/v1'
const authables = []
const currency = __('جنيه', 'EGP')

export const managementTitle = __('إدارة صيانة الاتصالات', 'Integr8me Management')

// Form Options
export const formOptions = {
  keepData: false,
  clearInputs: false,
  maxUpload: 5000
}

// Table Options
export const tableOptions = {
  autoSerialNumber: false,
}

// PDF Options
export const pdfOptions = {
  allowPrint: false,
  logo
}

// Global Options
export const globalOptions = {
  enableAccessGuard: false,
}

export const Notifications = () => {
  const {notifications} = useSelector((state) => state)
  const locationPath = window.location.pathname

  return (
    <Box maxWidth={400}>
      {notifications?.notifications.map(notification => (
        <>
          {notification.href === locationPath && (
            <NotificationItem notification={notification} />
          )}
        </>
      ))}
    </Box>
  )
}

export
{
  baseUrl,
  baseDomain,
  authables,
  currency,

}
