import React, {useState} from 'react'
import {Router} from 'react-router-dom'
import {renderRoutes} from 'react-router-config'
import {createBrowserHistory} from 'history'
import MomentUtils from '@date-io/moment'
import {Provider as StoreProvider} from 'react-redux'
import {ThemeProvider} from '@material-ui/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import 'react-perfect-scrollbar/dist/css/styles.css'
import {theme, themeWithRtl} from './theme'
import configureStore from './redux/configureStore'
import routes from './routes'
import ScrollReset from './components/ScrollReset'
import StylesProvider from './components/StylesProvider'
import DirectionToggle from './components/DirectionToggle'
import {IntlProvider} from 'react-intl'
import * as messages_ar from './locales/ar'
import * as messages_en from './locales/en'
import {PersistGate} from 'redux-persist/integration/react'

import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './mock'
import './assets/scss/main.scss'
import AuthGuard from "./components/AuthGuard"
import DetailsPanel from "./admin-utils/Components/Page/DetailsPanel"
import {PusherNotifier} from "./admin-utils"
import __ from "./admin-utils/LanguageHelper"

const history = createBrowserHistory()
const store = configureStore()

const savedDirection = localStorage.getItem('direction')

function App() {
  const [direction, setDirection] = useState(savedDirection)

  const handleDirectionToggle = () => {
    setDirection((prevDirection) => {
      const direction = prevDirection === 'ltr' ? 'rtl' : 'ltr'
      localStorage.setItem('direction', direction)
      return direction
    })

    setTimeout(() => {
      window.location.reload()
    })
  }

  const messages = {
    'en': messages_en,
    'ar': messages_ar,
  }

  const language = direction === 'rtl' ? 'ar' : 'en'
  const OtherLanguage = direction === 'rtl' ? 'en' : 'ar'

  return (
    <StoreProvider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <IntlProvider locale={language} messages={messages[language].default}>
          <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
            <StylesProvider direction={direction}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={history}>
                  <ScrollReset/>

                  <AuthGuard permission={'dashboard_browse'}>
                    {renderRoutes(routes)}

                    <DetailsPanel
                      ref={c => {
                        if (c) DetailsPanel.detailsPanelInstance = c
                      }}
                    />

                    <PusherNotifier
                      appKey={'a94d0412a4f2209a575c'}
                      channelName={'orders'}
                      event={'notification'}
                      message={__('هناك طلب جديد تم حجزه', 'New order has been requested')}
                    />
                  </AuthGuard>
                </Router>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </IntlProvider>
      </PersistGate>
    </StoreProvider>
  )
}

export default App
