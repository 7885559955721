/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import BusinessIcon from '@material-ui/icons/Business';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import InputIcon from '@material-ui/icons/Input'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CreateIcon from '@material-ui/icons/Create';

import {FormattedMessage} from "react-intl"
import __ from "src/admin-utils/LanguageHelper";

export default [
  {
    // subheader: __(' لوحة التحكم', 'Dashboard'),
    items: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon,
        hideInHome: true
      },
    ]
  },
  {
    // subheader: __('المشاريع', 'Projects'),
    items: [
      {
        title: 'Clients',
        href: '/clients',
        icon: HowToRegIcon
      },
      {
        title: 'Accounts',
        href: '/accounts',
        icon: AccountTreeIcon
      },
    ]
  },
  {
    subheader: 'User Management',
    items: [
      // {
      //   title: 'Roles',
      //   href: '/user/management/roles',
      //   icon: CreateIcon,
      // },
      {
        title: 'Administrators',
        href: '/user/management/users',
        icon: GroupAddIcon,
      },
    ]
  },
  {
    items: [
      {
        title: __('تسجيل الخروج', 'Log Out'),
        href: '/auth/login',
        icon: InputIcon,
        alwaysShow: true
      },
    ]
  },
]
