export default {
  general: {
    primaryColor: '#3f126b',
    secondaryColor: '#3E9FED',
  },
  login: {
    backgroundColor: 'rgba(0,0,0,0.16)',
    logo: {
      width: 200,
      height: 'auto',
    },
    form: {
      textColor: '#333',
      labelColor: '#3f126b',
      borderColor: '#3f126b',
      buttonTextColor: '#fff',
    },
  },
  menu: {
    backgroundColor: '#3f126b',
    logo: {
      width: 200,
      height: 'auto',
    },
    header: {
      backgroundColor: '#fff',
      borderColor: '#3d3d58',
      // You can add any css here
      paddingBottom: 0,
      paddingTop: 10,
    },
    items: {
      textColor: '#fff',
      iconColor: '#fff',
      activeIconColor: '#FFFFFF',
      activeBackgroundColor: '#24a0ea',
      activeTextColor: '#FFFFFF',
      subHeaderColor: '#c77dfc',
    },
  },
};
