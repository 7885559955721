import React, {useEffect, useState} from 'react'
import Pusher from "pusher-js"
import {Howl, Howler} from 'howler'
import {Slide} from "@material-ui/core"
import __ from "../../LanguageHelper"
import Snackbar from "@material-ui/core/Snackbar"

const PusherNotifier = ({appKey, channelName, event, message, rest}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {

    const sound = new Howl({
      src: '/sound/alert.mp3'
    })

    const pusher = new Pusher(appKey, {
      cluster: 'eu',
      encrypted: true
    })

    const channel = pusher.subscribe(channelName)
    channel.bind(event, function (data) {
      setOpen(true)
      sound.play()
    })
  }, [])

  return (
    <Snackbar
      {...rest}
      anchorOrigin={{vertical: 'top', horizontal: __('left', 'right')}}
      key={`loader`}
      open={open}
      autoHideDuration={4000}
      TransitionComponent={Slide}
      onClose={() => setOpen(false)}
      message={message}
    />
  )
}

export default PusherNotifier
