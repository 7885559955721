import apisauce from 'apisauce'
import {baseUrl} from '../../utils/globals'

const api = apisauce.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache"
  },
  timeout: 10000
})

const getProfile = async (token) => {
  const userRequest = await api.get('user/profile', {
    include: 'roles'
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  })

  if (userRequest.status === "error") {
    return false
  }

  let user = userRequest.data.data

  if (!user) return false

  let userData = {
    user: {
      id: user.id,
      name: user.name,
      email: user.email,
    },
    role: user.roles,
    token,
  }

  if (!userData.role) {
    return false
  }

  return userData
}

const checkLogin = async (data) => {
  const response = await api.post('oauth/token', {
    ...data,
    client_id: 2,
    client_secret: 'kjhk6WQChGhvC487PIM1BYgK3KevVVo4afKEQexH',
    grant_type: 'password',
    scope: ''
  })

  let responseData = response.data

  if (!responseData) {
    return false
  }

  if (responseData.error) {
    return false
  }

  return getProfile(responseData.access_token).finally()
}

const logout = async (endpoint, data) => {
  return await api.post(endpoint, data)
}

export {
  getProfile,
  checkLogin,
  logout
}
