/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import WidgetsIcon from '@material-ui/icons/Widgets';
import AppsIcon from '@material-ui/icons/Apps';
import BallotIcon from '@material-ui/icons/Ballot';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import RateReviewIcon from '@material-ui/icons/RateReview';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarsIcon from '@material-ui/icons/Stars';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import EmailIcon from '@material-ui/icons/Email';
import FilterTiltShiftIcon from '@material-ui/icons/FilterTiltShift';
import UpdateIcon from '@material-ui/icons/Update';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GridOnIcon from '@material-ui/icons/GridOn';
import FlagIcon from '@material-ui/icons/Flag';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import InputIcon from '@material-ui/icons/Input'


import {FormattedMessage} from "react-intl"
import __ from "src/admin-utils/LanguageHelper";

export default [
  // {
  //   subheader: __('العملاء ', 'Clients'),
  //   items: [
  //     {
  //       title: __('العملاء', 'Clients'),
  //       href: '/clients',
  //       icon: AccountBoxIcon,
  //       color: 'teal'
  //     },
  //     {
  //       title: __('الفروع', 'Branches'),
  //       href: '/branches',
  //       icon: GridOnIcon,
  //       color: 'indigo'
  //     },
  //   ]
  // },
  {
    subheader: __('الحجوزات ', 'Appointments'),
    items: [
      {
        title: __('العملاء', 'Clients'),
        href: '/clients',
        icon: AccountBoxIcon,
        color: 'pink'
      },
      {
        title: __('كل الحجوزات', 'All Appointments'),
        href: '/orders/all',
        icon: UpdateIcon,
        color: 'pink'
      },
      {
        title: __('الحجوزات العاية الجديدة', 'New Regular Appointments'),
        href: '/orders/new',
        icon: ListAltIcon,
        color: 'pink'
      },
      {
        title: __('حجوزات الغرفة الخاصة الجديدة', 'New Private Room Appointments'),
        href: '/private/new',
        icon: FormatListBulletedIcon,
        color: 'pink'
      },
    ]
  },
  // {
  //   subheader: __('الخدمات و المنتجات', 'Services And Products'),
  //   items: [
  //     {
  //       title: __('الخدمات', 'Services'),
  //       href: '/services',
  //       icon: WidgetsIcon,
  //       items:
  //         [
  //           {
  //             title: __('الاقسام', 'Categories'),
  //             href: '/services/categories',
  //           },
  //           {
  //             title: __('الخدمات', 'Services'),
  //             href: '/services/services',
  //           },
  //         ]
  //     },
  //     {
  //       title: __('المنتجات', 'Products'),
  //       href: '/products',
  //       icon: BallotIcon,
  //       items:
  //         [
  //           {
  //             title: __('الاقسام', 'categories'),
  //             href: '/products/categories',
  //           },
  //           {
  //             title: __('المنتجات', 'Products'),
  //             href: '/products/products',
  //           },
  //         ]
  //     },
  //   ]
  // },
  // {
  //   subheader: __('العروض و الخصومات', 'Offers And Discounts'),
  //   items: [
  //     {
  //       title: __('العروض', 'Offers'),
  //       href: '/offers',
  //       icon: LocalOfferIcon,
  //     },
  //     {
  //       title: __('الخصومات', 'Discounts'),
  //       href: '/discounts',
  //       icon: LocalAtmIcon,
  //     },
  //   ]
  // },
  // {
  //   subheader: __('تقارير التقييم', 'Rating Reports'),
  //   items: [
  //     {
  //       title: __('تقارير التقييم', 'Rating Reports'),
  //       href: '/rating',
  //       icon: RateReviewIcon,
  //       items:
  //         [
  //           // {
  //           //   title: __('تقييم الطلبات', 'Order Rating '),
  //           //   href: '/rating/order',
  //           //   icon: StarHalfIcon
  //           // },
  //           {
  //             title: __('تقييم الخدمات', 'Service Rating '),
  //             href: '/rating/service',
  //           },
  //           {
  //             title: __('التقييم العام', 'Generic Rating '),
  //             href: '/rating/generic',
  //           },
  //         ]
  //     },
  //   ]
  // },
  // {
  //   items: [
  //     {
  //       title: __('الحملات الاعلانية', 'Campaigns'),
  //       href: '/campaigns',
  //       icon: EmailIcon
  //     },
  //   ]
  // },
  //
  // {
  //   subheader: __('الاعدادات', 'Settings'),
  //   items: [
  //     {
  //       title: __('ساعات العمل', 'Working Schedule'),
  //       href: '/hours',
  //       icon: QueryBuilderIcon,
  //       items:
  //         [
  //           {
  //             title: __('فترات العمل', 'Working Shifts'),
  //             href: '/shifts',
  //           },
  //           {
  //             title: __('ساعات العمل', 'Working Schedule'),
  //             href: '/slots',
  //           },
  //           // {
  //           //   title: __('مواعيد اليوم', 'Today Slots'),
  //           //   href: '/todayslots',
  //           // },
  //         ]
  //     },
  //     {
  //       title: __('الموظفات', 'Workers'),
  //       href: '/workers',
  //       icon: SupervisedUserCircleIcon,
  //     },
  //     {
  //       title: __('اعدادات التطبيق', 'App Settings'),
  //       href: '/settings/app',
  //       icon: SettingsApplicationsIcon
  //     },
  //     {
  //       title: __('الاشعارات', 'Notifications'),
  //       href: '/settings/notifications',
  //       icon: NotificationsActiveIcon
  //     },
  //     {
  //       title: __('المدن', 'Cities'),
  //       href: '/settings/cities',
  //       icon: FlagIcon
  //     },
  //     {
  //       title: __('العلامات التجارية', 'Brands'),
  //       href: '/settings/brands',
  //       icon: BrandingWatermarkIcon
  //     },
  //   ]
  // },
  // {
  //   subheader: __('ادارة المستخدمين', 'User Management'),
  //   items: [
  //     {
  //       title: __('ادارة المستخدمين', 'User Management'),
  //       href: '/user/management',
  //       icon: AssignmentIndIcon,
  //       items: [
  //         {
  //           title: __('الادوار', 'Roles'),
  //           href: '/user/management/roles',
  //         },
  //         {
  //           title: __('العملاء', 'Users'),
  //           href: '/user/management/users',
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   items: [
  //     {
  //       title: __('تسجيل الخروج', 'Log Out'),
  //       href: '/auth/login',
  //       icon: InputIcon,
  //       hideInHome: true
  //     },
  //   ]
  // },
]
