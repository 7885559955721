import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setNotification: ['forKey', 'count', 'action'],
  saveNotifications: ['notifications'],
  clear
})

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  count: {},
  notifications: []
})

/* ------------- Reducers ------------- */
export const changeNotification = (state = INITIAL_STATE, {forKey, count = 1, action = 'update'}) => {
  const notifications = {...state.count}

  // update notifications count for specific key
  let newCount = count
  if(action === 'add') {
    newCount = notifications[forKey] + count
    if(!newCount > 0) {
      newCount = 1
    }
  }

  if(action === 'remove') {
    newCount = notifications[forKey] - 1
    notifications[forKey] = newCount

    return {...state, count: notifications, notifications: [...state.notifications.filter(i => i.id !== count)]}
  }

  notifications[forKey] = newCount

  return {...state, count: notifications}
}

export const saveNotifications = (state = INITIAL_STATE, {notifications = []}) => {
  return {...state, notifications}
}

export const clear = () => {
  return INITIAL_STATE
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NOTIFICATION]: changeNotification,
  [Types.SAVE_NOTIFICATIONS]: saveNotifications,
  [Types.CLEAR]: clear
})
